
 
export class RouteDefines {
    public static readonly ROUTE_LEGAL_COOKIES:string = "/legal/cookies";
    public static readonly ROUTE_LEGAL_PRIVACY:string = "/legal/privacy";
    public static readonly ROUTE_HOME:string = "/home";
    public static readonly ROUTE_CONTACT:string = "/contact";
    public static readonly ROUTE_SOLUTION:string = "/solutions";


    
 }
