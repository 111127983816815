import React, { useState, useEffect  } from 'react';
import { Console } from 'console';

const Page_Legal_Cookies = () => {
    
    

    useEffect(() => {
        
      return () => {
        
        }
      }, []);


    return (
        <div>
            Kaimonos.com uses cookies/local storage to store your cookie consent.
            <br></br>
            
        </div>
    );
}

export default Page_Legal_Cookies;
