import React, {useState, useEffect}  from 'react';
import logo from './logo.svg';
import './App.css';


import CookieConsent, { Cookies } from "react-cookie-consent";

import MainMenu from './MainMenu'
import MainFooter from './MainFooter'
import Page_Home from './Page_Home';
import Page_Contact from './Page_Contact';
import Page_Legal from './Page_Legal';

import {Route, BrowserRouter , Switch, Redirect, Link} from 'react-router-dom'
import { RouteDefines } from './routes';


enum Language {
  En = "en-US",
  Nl = "nl-BE"
}

function App() {

  const cookieNameConsent = 'CookieConsent';
  const cookieNameConsentLegacy = 'CookieConsent-legacy';

  const [cookiesConsentGiven, setcookiesConsentGiven] = useState(Cookies.get(cookieNameConsent) === 'true' || Cookies.get(cookieNameConsentLegacy) === 'true');

  let language:Language = Language.Nl;
  
    useEffect(() => {
  }, []);

  //<Route path="/"     exact render={(props) => <div>}   />
  //<Route path={RouteDefines.ROUTE_MAPS} exact render={(props) => <Page_Maps cookiesConsentGiven={cookiesConsentGiven} language={language}  {...props}/>}   />
   
  
  return (
    <BrowserRouter>
      <div className="App" style={{ width:'100vw', margin:'auto', maxWidth:'960px'  }}>
        <CookieConsent  /*https://www.npmjs.com/package/react-cookie-consent*/ 
          location="top"
          cookieName={cookieNameConsent}
          onAccept={() => {
            setcookiesConsentGiven(true);
          }} >This website uses cookies to enhance the user experience.</CookieConsent>
        <MainMenu/>
        <br/>
        <div style={{minHeight:'80vh'}}>
        <Switch>
          <Route path="/"     exact component={Page_Home} />
          <Route path={RouteDefines.ROUTE_HOME} exact component={Page_Home} />
          <Route path={RouteDefines.ROUTE_CONTACT} exact component={Page_Contact} />
          <Route path="/legal" exact component={Page_Legal} />
          <Route path={RouteDefines.ROUTE_LEGAL_COOKIES} exact component={Page_Legal} />
          <Route path={RouteDefines.ROUTE_LEGAL_PRIVACY} exact component={Page_Legal} />
          <Redirect from='*' to='/' />
        </Switch>
        </div>
        <br/>
        <br/>
        <MainFooter/>
      </div>
    </BrowserRouter>
  );
}

export default App;
