import React, {  } from 'react';




const Page_Home = () => {
  
    return (
        <div>
             <i>Kaimonos software development</i>. 
        </div>
    );
}

export default Page_Home;